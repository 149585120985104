<template>
  <el-form :model="formData" :rules="editRules" ref="menuForm" label-width="80px" class="menuForm">
    <el-form-item label="父级菜单">
      <el-input v-model="formData.parentMenuName" clearable :disabled="true" class="pc-input-readonly-color pc-input-col3"> </el-input>
    </el-form-item>
    <el-form-item label="菜单编码" prop="menuCode">
      <el-input
        v-model="formData.menuCode"
        clearable
        :disabled="type === 'UPDATE' || type === 'VIEW'"
        :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="Icon编码" prop="icon">
      <el-input
        v-model="formData.icon"
        clearable
        :icon="formData.icon"
        :disabled="type === 'VIEW'"
        :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="菜单名称" prop="menuName">
      <el-input
        v-model="formData.menuName"
        clearable
        :disabled="type === 'VIEW'"
        placeholder="请输入中文汉字"
        :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="菜单排序" prop="number">
      <el-input
        v-model.number="formData.number"
        clearable
        :disabled="type === 'VIEW'"
        placeholder="请输入整数"
        :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="菜单地址" prop="url">
      <el-input v-model="formData.url" clearable :disabled="type === 'VIEW'" :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']">
      </el-input>
    </el-form-item>
    <el-form-item label="备注说明" prop="remark">
      <el-input
        type="textarea"
        v-model="formData.remark"
        clearable
        :disabled="type === 'VIEW'"
        :class="[type === 'VIEW' ? 'pc-input-readonly-color' : '', 'pc-input-col3']"
      >
      </el-input>
    </el-form-item>
    <el-form-item label=" ">
      <el-button type="primary" v-if="type !== 'VIEW'" @click="submitForm('menuForm')">保 存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { httpPost } from '@/api';
export default {
  props: {
    data: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
  },
  watch: {
    data(newData) {
      this.formData = newData;
    },
  },
  created() {
    this.formData = this.data;
  },
  methods: {
    submitForm() {
      console.log('valid-');
      this.$refs.menuForm.validate((valid) => {
        console.log('valid', valid);
        if (!valid) {
          return false;
        }
        this.saveMenuFn();
      });
    },
    saveMenuFn() {
      this.ajax({
        url: '/uac/menu/save',
        data: this.formData || '',
        loading: true,
        success: () => {
          this.$emit('on-submit');
        },
      });
    },
  },
  data() {
    const checkMenuCode = async (rule, value, callback) => {
      if (value !== '' && value !== undefined && value !== null) {
        const reg = /^[A-Za-z0-9_]+$/;
        const val = reg.test(value);
        if (val !== true) {
          callback(new Error('只能由数字字母或者下划线组成'));
        } else {
          const param = {};
          param.menuCode = value;
          param.menuId = this.formData.id;
          const res = await httpPost('/uac/menu/checkMenuCode', param);
          if (res.result === false) {
            callback(new Error('编码已存在'));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error('请输入菜单编码'));
      }
    };
    const checkMenuName = async (rule, value, callback) => {
      if (value !== '' && value !== undefined && value !== null) {
        const reg = /^[\u4e00-\u9faf]+$/;
        const val = reg.test(value);
        if (val !== true) {
          callback(new Error('菜单名称只能为汉字'));
        } else {
          const param = {};
          param.menuName = value;
          param.menuId = this.formData.id;
          param.pid = this.formData.pid;
          const res = await httpPost('/uac/menu/checkMenuName', param);
          if (res.result === false) {
            callback(new Error('菜单名称已存在'));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error('内容不能为空'));
      }
    };
    const checkNumber = (rule, value, callback) => {
      if (value !== '' && value !== undefined && value !== null) {
        const reg = /^[0-9]*$/;
        const val = reg.test(value);
        if (val !== true) {
          callback(new Error('只能输入整数'));
        } else {
          callback();
        }
      } else {
        callback(new Error('只能输入整数'));
      }
    };
    const checkUrl = async (rule, value, callback) => {
      if (value !== '' && value !== undefined && value !== null) {
        const reg = /^[/]/ || /^[a-zA-Z0-9_]+$/;
        const val = reg.test(value);
        if (val !== true) {
          callback(new Error('格式错误'));
        } else {
          const param = {};
          param.url = value;
          param.menuId = this.formData.id;
          const res = await httpPost('/uac/menu/checkMenuUrl', param);
          if (res.result === false) {
            callback(new Error('已存在'));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error('只能输入整数'));
      }
    };
    const checkRules = {
      menuCode: [
        { min: 1, max: 30, message: '长度不能大于30', trigger: 'blur' },
        { validator: checkMenuCode, trigger: 'blur' },
      ],
      iconCode: [{ required: true, message: '内容不能为空', trigger: 'change' }],
      menuName: [
        { min: 1, max: 30, message: '长度不能大于30', trigger: 'blur' },
        { validator: checkMenuName, trigger: 'blur' },
      ],
      number: [
        { type: 'number', message: '长度不能大于2个字符', trigger: 'blur' },
        //          { min: 0, max: 1, message: '菜单序号长度不能大于2个字符', trigger: 'blur' },
        { validator: checkNumber, trigger: 'blur' },
      ],
      url: [
        { min: 0, max: 100, message: '长度不能大于100', trigger: 'blur' },
        { validator: checkUrl, trigger: 'blur' },
      ],
      remark: [{ min: 0, max: 200, message: '长度不能大于150', trigger: 'blur' }],
    };
    return {
      formData: this.data,
      editRules: checkRules,
    };
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.menuForm .fa-user {
  position: absolute;
  top: 0;
  margin-right: 0;
}
</style>
